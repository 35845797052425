@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Merriweather&display=swap);
.map {
  width: 100%;
  height: 100vh;
  position: relative;
  outline: none;
}
.background-image {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover !important;
  -webkit-filter: grayscale(80%);
          filter: grayscale(80%);
}
.fadeOut {
  opacity:0;
}
.fadeIn {
  opacity:0.3;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.title {
  position: absolute;
  left: 0;
  top: 40px;
  color: #11444D;
  font-size: 30px;
  z-index: 100;
  font-weight: 700;
  padding: 5px 20px 5px 40px;
}
.title:hover {
  color: #11444D;
  opacity: 0.8;
}
@media (max-width: 600px) {
  .title {
    font-size: 20px;
  }
}
.logo {
  position: absolute;
  right: 20px;
  padding: 0;
  bottom: 40px;
  text-indent: -9999px;
  background: url('/assets/logo.png') no-repeat center center;
  background-size: 90%;
  width: 300px;
  height: 52px;
}
@media (max-width: 600px) {
  .logo {
    display: none;
  }
}
.loading {
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1000;
}

.mapboxgl-popup {
  width: 280px;
  font-family: "Lato", sans-serif;
}
.mapboxgl-popup a {
  text-decoration: none;
  color: #11444D;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-family: "Merriweather", serif;
}
.mapboxgl-popup a:hover {
  opacity: 0.8;
}
.mapboxgl-popup .ant-btn {
  color: #fff;
  border: none;
}
.mapboxgl-popup .btn-close-popup {
  position: absolute;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  right: 2px;
  top: 2px;
}
.mapboxgl-popup .btn-close-popup:hover {
  opacity: 0.6;
}
.legend {
  position: absolute;
  left: 40px;
  bottom: 50px;
  z-index: 140;
  color: #11414C;
}
.legend .partner {
  width: 20px;
  height: 20px;
  opacity: 0.8;
  background: #fff;
  border: 8px solid #FC8404;
  border-radius: 50%;
  display: inline-block;
}
.legend .story {
  width: 10px;
  opacity: 0.8;
  height: 10px;
  background: #FC8404;
  border-radius: 50%;
  display: inline-block;
}
.intro {
  position: absolute;
  right: 0;
  left: 0;
  width: 50%;
  top: 30%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
}
.intro p {
  display: block;
  width: 100%;
  line-height: 1.5;
  font-family: "Merriweather", serif;
  font-size: 20px;
  color: #11444D;
}
.intro .btn-explore {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #11444D;
  font-size: 24px;
}
.intro .btn-explore:hover {
  opacity: 0.8;
}
.intro .btn-join {
  position: absolute;
  color: #11444D;
  right: 50px;
  bottom: 0;
}
@media(max-width: 600px) {
  .intro {
    width: 80%;
  }
  .intro .btn-join {
    left: 0;
    right: auto;
    bottom: -40px;
  }
}

.partners {
  background: #91BCC6;
}
.partners h2 {
  text-align: center;
  text-transform: uppercase;
  color: #11414C;
  font-size: 14px;
  letter-spacing: 6px;
}
.partners .content {
  max-width: 1000px;
  padding: 80px 0;
  margin: 0 auto;
}
.partners .partner {
  text-align: center;
  height: 120px;
  text-indent: -99999px;
  opacity: 1;
  display: block;
}
.palm-beach-post {
  background: url('/assets/palm-beach-post.png') no-repeat center center;
  background-size: 60%;
}
.chicago-tribune {
  background: url('/assets/chicago-tribune.png') no-repeat center center;
  background-size: 60%;
}
.boston-globe {
  background: url('/assets/boston-globe.png') no-repeat center center;
  background-size: 60%;
}
.new-york-times {
  background: url('/assets/new-york-times.png') no-repeat center center;
  background-size: 60%;
}
.seattle-times {
  background: url('/assets/seattle-times.png') no-repeat center center;
  background-size: 60%;
}
.miami-herald {
  background: url('/assets/miami-herald.png') no-repeat center center;
  background-size: 60%;
}
.sun-sentinel {
  background: url('/assets/sun-sentinel.png') no-repeat center center;
  background-size: 60%;
}
.wlrn {
  background: url('/assets/wlrn.png') no-repeat center center;
  background-size: 50%;
}
.orlando-sentinel {
  background: url('/assets/orlando-sentinel.png') no-repeat center center;
  background-size: 60%;
}
.tampa-bay-times {
  background: url('/assets/tampa-bay-times.png') no-repeat center center;
  background-size: 60%;
}
.times-picayune {
  background: url('/assets/times-picayune.png') no-repeat center center;
  background-size: 60%;
}
.advocate {
  background: url('/assets/advocate.png') no-repeat center center;
  background-size: 60%;
}
.asbury-park-press {
  background: url('/assets/asbury-park-press.png') no-repeat center center;
  background-size: 40%;
}
.savannah-now {
  background: url('/assets/savannah-now.png') no-repeat center center;
  background-size: 60%;
}
.climate-central {
  background: url('/assets/climate-central.png') no-repeat center center;
  background-size: 60%;
}
.gothamist {
  background: url('/assets/gothamist.png') no-repeat center center;
  background-size: 50%;
}
.kqed {
  background: url('/assets/kqed.png') no-repeat center center;
  background-size: 40%;
}
.maptian {
  background: url('/assets/maptian-logo.png') no-repeat center center;
  background-size: 50%;
}


.mapboxgl-popup-content {
  background: #A6CAD2 !important;
  color: #555;
  padding: 20px !important;
}
.mapboxgl-popup-content a:hover {
  color: #333;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background: none !important;
  opacity: 0.4;
  top: 10px !important;
}

body {
  margin: 0;
  background: #fff;
  font-family: "Lato", sans-serif;
}

